export type Photo = {
  src: string;
  description?: string;
};

export enum ScreenWidth {
  xs = 640,
  sm = 768,
  md = 1024,
  lg = 1280,
  xl = 1536,
}
