const DesprePage = () => {
  return (
    <main className="flex sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[50%] flex-grow self-center justify-center m-auto">
      <article className="flex flex-col bg-white py-4 px-8  items-center gap-4 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Despre
        </h1>
        <div className="rounded-xs border flex flex-col bg-white py-8 px-4 items-center gap-2 w-full">
          <p className="text-lg text-medium">
            {' '}
            În anul 1922 avea să se ridice o școală cu profil de specialitate,
            care, ulterior, avea să devină Liceul Industrial de Băieți. În anul
            1948, după prima reformă a învățământului, Ministerul Agriculturii
            preia baza materială a școlii și dă un nou profil, care avea sa
            devină, la 01.09.1949, Școala Profesională de Mecanică. Datorită
            nevoilor stringente ale noii agriculturi, la 01.09.1953, școala va
            funcționa sub denumirea de Școala Profesională de Tractoriști. Din
            anul 1955, pentru școală începe etapa a doua, funcționând sub numele
            de Școala Profesională de Mecanici Agricoli. Denumirea și meseriile
            au fost schimbate de-a lungul anilor, după cum o cereau interesele
            locale și generale, până când, în anul 1990, școala capată denumirea
            de Grupul Școlar Agricol Corabia. Începând cu 01.09.2008, Grupul
            Școlar Agricol funcționează sub denumirea de Colegiul Tehnic
            ”Danubius” Corabia.
          </p>
        </div>
        <div className="rounded-xs border flex flex-col bg-white py-4 px-8 items-center gap-2 w-full">
          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">1922</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                În anul 1922 avea să se ridice o școală cu profil de
                specialitate, care, ulterior, avea să devină Liceul Industrial
                de Băieți.
              </p>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">1948</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                În anul 1948, după prima reformă a învățământului, Ministerul
                Agriculturii preia baza materială a școlii și dă un nou profil,
                care avea sa devină, la 01.09.1949, Școala Profesională de
                Mecanică.
              </p>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">1953</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                Datorită nevoilor stringente ale noii agriculturi, la
                01.09.1953, școala va funcționa sub denumirea de Școala
                Profesională de Tractoriști.
              </p>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">1955</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                Din anul 1955, pentru școală începe etapa a doua, funcționând
                sub numele de Școala Profesională de Mecanici Agricoli.
              </p>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">1990</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                Denumirea și meseriile au fost schimbate de-a lungul anilor,
                după cum o cereau interesele locale și generale, până când, în
                anul 1990, școala capată denumirea de Grupul Școlar Agricol
                Corabia.
              </p>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-2xl font-bold mt-4 mb-2">2008</h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border-2">
              <p className="text-lg text-medium">
                Începând cu 01.09.2008, Grupul Școlar Agricol funcționează sub
                denumirea de Colegiul Tehnic ”Danubius” Corabia.
              </p>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
};

export default DesprePage;
