const DummyPhotoCard = () => {
  return (
    <div className="max-w-sm flex flex-col min-w-full border rounded-xl relative aspect-square bg-gray-100">
      <div className="w-full object-cover aspect-square rounded-xl" />
      <p className="text-white text-xl font-bold rounded-xl absolute bottom-2 w-full"></p>
    </div>
  );
};

export default DummyPhotoCard;
