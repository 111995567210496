import { Link, useLocation } from 'react-router-dom';
import photoA from '../assets/20150616_124142.jpg';
import photoB from '../assets/20150616_124211.jpg';
import photoC from '../assets/20150616_124235.jpg';
import photoD from '../assets/20150616_124400.jpg';
import photoE from '../assets/20150619_111102.jpg';
import photoF from '../assets/20150622_101244.jpg';
import photoG from '../data/ms/DSCN6679.jpg';

import PhotoCard from '../components/PhotoCard';
import NotFoundPage from './NotFound';
import { useEffect } from 'react';

type Photo = {
  src: string;
  description?: string;
};

const photos: Photo[] = [
  { src: photoA, description: 'Profesor laborator' },
  { src: photoB, description: 'Laborator 1' },
  { src: photoC, description: 'Laborator 2' },
  { src: photoD, description: 'Laborator 3' },
  { src: photoE, description: 'Afis' },
  { src: photoF, description: 'Festivitate 2022' },
  { src: photoG, description: 'Concurs 2023' },
];

const maxPhotosPerPage = 6;

const pages: Record<number, Photo[]> = {};

photos.forEach((photo, index) => {
  const currentPage = Math.floor(index / maxPhotosPerPage) + 1;

  if (!pages[currentPage]) {
    pages[currentPage] = [];
  }

  pages[currentPage].push(photo);
});

const pageNumberList = Object.keys(pages);

const GaleriePage = () => {
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const currentPage = parseInt(urlParams.get('page') || '1', 10);

  const { pathname } = useLocation();

  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  const currentPagePhotos = pages[currentPage] || [];

  if (!currentPagePhotos.length) {
    return <NotFoundPage />;
  }

  return (
    <main className="flex sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[50%] m-auto">
      <article className="flex flex-col bg-white py-4 px-8 rounded-md items-center gap-2 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Galerie
        </h1>

        <h2 className="text-md font-medium tracking-tight lg:text-md py-2 px-4 border bg-[#0D0C18] text-white  rounded-xl">
          Pagina {currentPage} / {pageNumberList.length}
        </h2>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-4 w-full">
          {currentPagePhotos.map(photo => (
            <PhotoCard
              key={photo.src}
              image={photo.src}
              description={photo.description}
            />
          ))}
        </div>

        <div className="mt-4 p-2">
          <p className="font-bold text-lg">Alege pagina:</p>
          <div className="flex gap-2 mt-4">
            <Link
              to={`?page=1`}
              className="button px-4 py-2 border bg-[#0D0C18] text-white font-bold rounded-xl"
            >
              PRIMA
            </Link>
            {pageNumberList.map(number => (
              <Link
                to={`?page=${number}`}
                className={`button px-3 py-1 bg-[#0D0C18] text-white font-bold rounded-xl border-4 border-black${
                  currentPage === parseInt(number) ? ' border-blue-500' : ''
                } `}
              >
                {number}
              </Link>
            ))}
            <Link
              to={`?page=${pageNumberList.length}`}
              className="button px-4 py-2 border bg-[#0D0C18] text-white font-bold rounded-xl"
            >
              ULTIMA
            </Link>
          </div>
        </div>
      </article>
    </main>
  );
};

export default GaleriePage;
