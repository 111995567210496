const ProiectePage = () => {
  return (
    <main className="flex w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[50%] m-auto">
      <article className="flex flex-col bg-white py-4 px-8 items-center gap-4 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Proiecte educationale
        </h1>
        <div className="rounded-xs border flex flex-col bg-white py-4 px-8 items-center gap-2 w-full">
          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-3xl font-bold  mb-2 mt-4">
              Muzeul o punte între trecut, prezent și viitor.
            </h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border shadow">
              <article className="proiect">
                <p>
                  Coordonatori: Diaconu Ionela, Modan Octavia, Răduca Viorica
                </p>
                <p>Durata: dec. 2015 - iun. 2016</p>
                <p>
                  Scop: cultivarea identității naționale și culturale pe baza
                  cunoașterii trecutului istoric, a tradițiilor locale și a
                  elementelor culturale specifice zonei geografice în care
                  trăiesc.
                </p>
                <p>Obiective specifice:</p>
                <ul>
                  <li>stimularea gustului pentru frumos;</li>
                  <li>
                    familiarizarea elevilor cu trecutul istoric și operele de
                    artă și obiectele expuse în muzeu;
                  </li>
                  <li>
                    formarea unei atitudini de respect față de opera de artă;
                  </li>
                  <li>
                    educarea respectului și toleranței față de tradițiile și
                    cultura altor etnii.
                  </li>
                </ul>
                <p>
                  Grupul țintă: elevii Colegiului Tehnic Danubius, cadrele
                  didactice și membri ai comunității locale.
                </p>
              </article>
            </div>
          </section>

          <section className="md:w-[90%] lg:w-[80%] xl:w-[70%]">
            <h2 className="text-3xl font-bold mb-2 mt-4">
              Dar din dar se face rai.
            </h2>
            <div className="min-h-16 py-4 px-8 rounded-xs border shadow">
              <article className="proiect">
                <p>Coordonatori: Parodel Florentina, Pană Marcela</p>
                <p>
                  Beneficiari: bătrânii Centrului Medico-Social și elevii
                  liceului.
                </p>
                <p>
                  Este un proiect social ce urmărește sensibilizarea comunității
                  în ceea ce privește nevoile bătrânilor singuri. Prin acest
                  proiect se urmărește strângerea de fonduri pentru ajutorarea
                  bătrânilor și implicarea în acțiuni de voluntariat și
                  activităti cultural-artistice menite să trezească și să
                  dezvolte în rândurile elevilor sentimente de generozitate,
                  responsabilitate și spirit de într-ajutorare.
                </p>
                <p>Obiective specifice:</p>
                <ul>
                  <li>
                    să ofere o stare psihologică optimistă vârstnicilor
                    centrului;
                  </li>
                  <li>
                    să îi încurajeze pe bătrânii izolați și retrași să
                    socializeze;
                  </li>
                  <li>
                    să cultive în rândul copiilor, tinerilor, întregii
                    comunități spiritul de într-ajutorare;
                  </li>
                  <li>să încurajeze voluntariatul.</li>
                </ul>
                <p>
                  Grupul țintă: elevii, tinerii, personalul didactic și
                  nedidactic din școala organizatoare, instituțiile partenere și
                  comunitatea locală.
                </p>
              </article>
            </div>
          </section>
        </div>
      </article>
    </main>
  );
};

export default ProiectePage;
