const ContactPage = () => {
  return (
    <main className="flex w-full sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[50%] m-auto">
      <article className="flex flex-col bg-white p-2 rounded-md  items-center gap-2 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Contact
        </h1>

        <div className="grid gap-4 w-full md:w-auto md:grid-cols-2 border-2 border-gray-200 p-4 rounded-md">
          <div>
            <div className="col-span-full">
              <p className="text-xl font-bold text-left">Director</p>

              <p className="text-lg font-medium text-left">Virdol Costel</p>
            </div>
            <div className="flex  items-center gap-4 p-4 border rounded-xl shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
              <a
                href="tel:0741277182"
                className="text-lg font-medium border-l pl-2 text-black"
              >
                0741277182
              </a>
            </div>
          </div>

          <div>
            <div className="col-span-full">
              <p className="text-xl font-bold text-left">Director ajunct</p>

              <p className="text-lg font-medium text-left">Danciulescu Doina</p>
            </div>
            <div className="flex  items-center gap-4 p-4 border rounded-xl shadow-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-black"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
              <a
                href="tel:0767032727"
                className="text-lg font-medium border-l pl-2 text-black"
              >
                0767032727
              </a>
            </div>
          </div>

          <div className="col-span-full">
            <p className="text-xl font-bold text-left">Email</p>
          </div>

          <div className="flex items-center gap-4 p-4 border rounded-xl shadow-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <rect width="20" height="16" x="2" y="4" rx="2"></rect>
              <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
            </svg>
            <a
              href="mailto:ctdcorabia@gmail.com"
              className="text-lg font-medium border-l pl-2 text-black"
            >
              ctdcorabia@gmail.com
            </a>
          </div>

          <div className="col-span-full">
            <p className="text-xl font-bold text-left">Telefon</p>
          </div>
          <div className="flex  items-center gap-4 p-4 border rounded-xl shadow-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
            </svg>
            <a
              href="tel:(0249) 560-744"
              className="text-lg font-medium border-l pl-2 text-black"
            >
              TEL: (0249) 560744
            </a>
          </div>
          <div className="flex  items-center gap-4 p-4 border rounded-xl shadow-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <path d="M3 3h18v4H3z"></path>
              <path d="M3 8v13h18V8"></path>
              <path d="M7 12h4v8H7z"></path>
              <path d="M15 12h2v8h-2z"></path>
              <path d="M11 12h2v8h-2z"></path>
              <path d="M3 18h18"></path>
            </svg>
            <a
              href="fax:(0249) 560-744"
              className="text-lg font-medium border-l pl-2 text-black"
            >
              FAX: (0249) 563833
            </a>
          </div>

          <div className="col-span-full">
            <p className="text-xl font-bold text-left">Adresa</p>
          </div>
          <div className="flex  items-center gap-4 p-4 border rounded-xl shadow-sm">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 text-black"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth="2"
            >
              <line x1="2" x2="5" y1="12" y2="12"></line>
              <line x1="19" x2="22" y1="12" y2="12"></line>
              <line x1="12" x2="12" y1="2" y2="5"></line>
              <line x1="12" x2="12" y1="19" y2="22"></line>
              <circle cx="12" cy="12" r="7"></circle>{' '}
            </svg>
            <a
              href="https://www.google.com/maps/search/?api=1&query=Str.+București,+nr.+29,+Corabia,+jud.+Olt"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-col items-center text-center border-l pl-2 text-black"
            >
              <p className="text-lg font-medium">
                Str. București, nr. 29, Corabia, jud. Olt
              </p>
            </a>
          </div>

          <div className="col-span-full w-full ">
            <iframe
              width="100%"
              height="500"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46916.50339166442!2d24.44972162107146!3d43.754038557523536!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40ac602e71414b19%3A0xecd9065301322b55!2sStrada%20Bucure%C8%99ti%2029%2C%20Corabia%20235300!5e0!3m2!1sen!2sro!4v1701268067928!5m2!1sen!2sro"
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </article>
    </main>
  );
};

export default ContactPage;
