import React from 'react';
import { FaFilePdf, FaFileWord, FaFileAlt } from 'react-icons/fa';

export type Document = {
  name: string;
  url: string;
  description?: string;
};

interface DownloadFileProps {
  document: Document;
}

const DownloadFile: React.FC<DownloadFileProps> = ({ document }) => {
  const getFileIcon = (url: string) => {
    if (url.endsWith('.pdf')) {
      return <FaFilePdf className="text-6xl text-[#0D0C18] mb-2" />;
    } else if (url.endsWith('.doc') || url.endsWith('.docx')) {
      return <FaFileWord className="text-6xl text-[#0D0C18] mb-2" />;
    } else {
      return <FaFileAlt className="text-6xl text-[#0D0C18] mb-2" />;
    }
  };

  return (
    <div className="flex flex-col items-center text-center bg-gray-100 border border-gray-300 px-4 py-3 rounded-md h-full">
      {getFileIcon(document.url)}
      <h3 className="text-sm font-bold mb-2">{document.name}</h3>
      {document.description && (
        <p className="text-xs text-gray-600 mt-1 mb-2">
          {document.description}
        </p>
      )}
      <div className="flex-grow"></div>
      <a
        href={document.url}
        download
        className="mt-2 px-2 py-2 bg-[#0D0C18] text-white text-xs rounded-md font-bold hover:bg-gray-800 w-full"
      >
        Descarcă
      </a>
    </div>
  );
};

export default DownloadFile;
