import { useEffect, useState } from 'react';
import { ScreenWidth } from '../types';

export const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState<ScreenWidth>(
    window.innerWidth
  );

  useEffect(() => {
    const updateScreenWidth = () => {
      const width = window.innerWidth;
      let newScreenWidth = ScreenWidth.xs; // Default to xs

      if (width >= 1280) {
        newScreenWidth = ScreenWidth.xl;
      } else if (width >= 1024) {
        newScreenWidth = ScreenWidth.lg;
      } else if (width >= 768) {
        newScreenWidth = ScreenWidth.md;
      } else if (width >= 640) {
        newScreenWidth = ScreenWidth.sm;
      }

      if (screenWidth !== newScreenWidth) {
        setScreenWidth(newScreenWidth);
      }
    };

    updateScreenWidth(); // Set initial screen width
    window.addEventListener('resize', updateScreenWidth);
    return () => window.removeEventListener('resize', updateScreenWidth);
  }, [screenWidth]);

  return screenWidth;
};
