const PhotoCard = ({ image, description }: any) => {
  return (
    <div className="max-w-sm flex flex-col min-w-full border rounded-xl relative aspect-square">
      <img
        className="w-full object-cover aspect-square rounded-xl"
        src={image}
        alt={description}
      />
      <p className="text-white text-xl font-bold rounded-xl absolute bottom-2 w-full">
        {description}
      </p>
    </div>
  );
};

export default PhotoCard;
