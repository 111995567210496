import { Photo } from '../types';
import PhotoGallery from '../components/PhotoGallery';

import { proiecte } from '../data/proiecte';

const misterioaseleStiintePhotos: Photo[] = proiecte.misterioasele_stiinte.map(
  (src): Photo => ({ src })
);

const CalendarProiecte = () => {
  return (
    <main className="flex sm:w-[90%] md:w-[80%] lg:w-[70%] xl:w-[50%] m-auto">
      <article className="flex flex-col bg-white py-4 rounded-md items-center gap-6 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem]">
          Calendar proiecte
        </h1>

        <div className="grid w-full bg-gray-100 py-6 px-2 gap-4 rounded-md">
          <h3 className="text-lg font-extrabold tracking-tight text-left lg:text-xl pl-12">
            1. Misterioasele Stiinte
          </h3>
          <PhotoGallery photos={misterioaseleStiintePhotos} />
        </div>

        <div className="grid w-full bg-gray-100 py-8 px-2 gap-4 rounded-md">
          <h3 className="text-lg font-extrabold tracking-tight text-left lg:text-xl pl-12">
            2. Training ”LET'S DO IT DANUBE"
          </h3>
          <PhotoGallery photos={misterioaseleStiintePhotos} />
        </div>

        <div className="grid w-full bg-gray-100 py-8 px-2 gap-4 rounded-md">
          <h3 className="text-lg font-extrabold tracking-tight text-left lg:text-xl pl-12">
            3. Târgul de oferte educaționale
          </h3>
          <PhotoGallery photos={misterioaseleStiintePhotos} />
        </div>
      </article>
    </main>
  );
};

export default CalendarProiecte;
