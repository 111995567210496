export default function Footer() {
  return (
    <div className="w-full pt-6 pb-6 flex items-center justify-center bg-black">
      <div className="container">
        <h2 className="text-2xl font-bold text-center text-white">
          Informații de contact
        </h2>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 mt-4 font-bold">
          <div className="flex flex-col items-center text-center">
            {/* <PhoneIcon /> */}
            <h3 className="text-lg text-white">Telefon</h3>
            <p className="text-white">TEL: (0249) 560744</p>
            <p className="text-white">FAX: (0249) 563833</p>
          </div>
          <div className="flex flex-col items-center text-center">
            {/* <EmailIcon /> */}
            <h3 className="text-lg text-white">Email</h3>
            {/* <p className="text-white">info@example.com</p> */}
            <p className="text-white">ctdcorabia@gmail.com </p>
          </div>
          <div className="flex flex-col items-center text-center">
            {/* <LocationIcon /> */}
            <h3 className="text-lg text-white">Adresa</h3>
            <p className="text-white">
              Str. București, nr. 29, Corabia, jud. Olt
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function PhoneIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="h-6 w-6 mb-2"
    >
      <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
    </svg>
  );
}

function EmailIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="h-6 w-6 mb-2"
    >
      <rect width="20" height="16" x="2" y="4" rx="2"></rect>
      <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7"></path>
    </svg>
  );
}

function LocationIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="white"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="h-6 w-6 mb-2"
    >
      <line x1="2" x2="5" y1="12" y2="12"></line>
      <line x1="19" x2="22" y1="12" y2="12"></line>
      <line x1="12" x2="12" y1="2" y2="5"></line>
      <line x1="12" x2="12" y1="19" y2="22"></line>
      <circle cx="12" cy="12" r="7"></circle>
    </svg>
  );
}
