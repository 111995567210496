import { FaExclamationTriangle } from 'react-icons/fa';

const NotFoundPage = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <div className="text-center bg-white p-8 rounded-lg shadow-md">
        <FaExclamationTriangle className="text-6xl text-yellow-500 mx-auto mb-4" />
        <h1 className="text-4xl font-bold">404: Pagina nu a fost găsită</h1>
        <button
          className="mt-4 px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors"
          onClick={() => (window.location.href = '/')}
        >
          Mergi la Pagina Principală
        </button>
      </div>
    </div>
  );
};

export default NotFoundPage;
