import DSC_0211 from './ms/DSC_0211.jpg';
import DSC_0212 from './ms/DSC_0212.jpg';
import DSC_0213 from './ms/DSC_0213.jpg';
import DSC_0216 from './ms/DSC_0216.jpg';
import DSC_0218 from './ms/DSC_0218.jpg';
import DSC_0219 from './ms/DSC_0219.jpg';
import DSC_0222 from './ms/DSC_0222.jpg';
import DSC_0224 from './ms/DSC_0224.jpg';
import DSC_0225 from './ms/DSC_0225.jpg';
import DSC_0236 from './ms/DSC_0236.jpg';
import DSC_0239 from './ms/DSC_0239.jpg';
import DSC_0244 from './ms/DSC_0244.jpg';
import DSC_0249 from './ms/DSC_0249.jpg';
import DSC_0250 from './ms/DSC_0250.jpg';
import DSC_0262 from './ms/DSC_0262.jpg';
import DSC_0299 from './ms/DSC_0299.jpg';
import DSCN6679 from './ms/DSCN6679.jpg';
import DSCN6683 from './ms/DSCN6683.jpg';
import DSCN6689 from './ms/DSCN6689.jpg';
import DSCN6694 from './ms/DSCN6694.jpg';
import DSCN6697 from './ms/DSCN6697.jpg';
import DSCN6701 from './ms/DSCN6701.jpg';
import DSCN6702 from './ms/DSCN6702.jpg';
import DSCN6704 from './ms/DSCN6704.jpg';
import DSCN6708 from './ms/DSCN6708.jpg';
import DSCN6710 from './ms/DSCN6710.jpg';
import DSCN6711 from './ms/DSCN6711.jpg';
import DSCN6712 from './ms/DSCN6712.jpg';
import DSCN6713 from './ms/DSCN6713.jpg';

export const proiecte = {
  misterioasele_stiinte: [
    DSC_0211,
    DSC_0212,
    DSC_0213,
    DSC_0216,
    DSC_0218,
    DSC_0219,
    DSC_0222,
    DSC_0224,
    DSC_0225,
    DSC_0236,
    DSC_0239,
    DSC_0244,
    DSC_0249,
    DSC_0250,
    DSC_0262,
    DSC_0299,
    DSCN6679,
    DSCN6683,
    DSCN6689,
    DSCN6694,
    DSCN6697,
    DSCN6701,
    DSCN6702,
    DSCN6704,
    DSCN6708,
    DSCN6710,
    DSCN6711,
    DSCN6712,
    DSCN6713,
  ],
};
