import { useEffect } from 'react';
import DownloadFile, { Document } from '../components/DownloadFile';

const documents: Document[] = [
  {
    name: 'REGULAMENT DE ORGANIZARE ȘI FUNCȚIONARE',
    url: '/documente/3-ROF-2024-2025.pdf',
    description: 'AN ȘCOLAR 2024 - 2025',
  },
  {
    name: 'RAPORT PRIVIND STRUCTURA ȘI CALITATEA ÎNVĂȚĂMÂNTULUI',
    url: '/documente/raport-calitate-2023-2024.pdf',
    description: 'ANUL ȘCOLAR 2023-2024',
  },
  {
    name: 'REGULAMENTUL INTERNAL AL Liceului Tehnologic "DANUBIUS" CORABIA',
    url: '/documente/RI-2024-2025.pdf',
    description: 'AN ȘCOLAR 2024 - 2025',
  },
];

const DocumentePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="flex w-90 m-auto">
      <article className="flex flex-col bg-white py-4 px-8 rounded-md items-center gap-2 w-full">
        <h1 className="text-4xl font-extrabold tracking-tight lg:text-5xl lg:leading-[3.5rem] w-full text-left">
          Documente
        </h1>

        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4 w-full">
          {documents.map(document => (
            <DownloadFile key={document.name} document={document} />
          ))}
        </div>
      </article>
    </main>
  );
};

export default DocumentePage;
